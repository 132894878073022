import React from 'react'
import { Link,useNavigate  } from 'react-router-dom';
import '../css/navbar.css';

const GameTopBar = ({intentData,multiBtn,multiBtn1,multiBtn2,updateState}) => {
  const navigate = useNavigate();
  
    return (
        <nav className="ps-fx ps-tp res-wth h-45-p pd-10 row-view sb-view z-i-120 bg-red-grad-4 topbar-shadow">
          
          <div className='row-view'>
           <Link className={`${multiBtn1 =='' ? '' : 'hide-v'}`} onClick={() => navigate(-1)}>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF"><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
           </Link>

           <span className={`cl-white ft-sz-18 mg-l-15 ${intentData.pageTitle =='' ? 'hide-v' : ''}`}>{intentData.pageTitle}</span>
          </div>

          <span className={`cl-white ft-sz-18 ${multiBtn ? '' : 'hide-v'}`} onClick={()=>updateState("multiBtn2")}>{multiBtn2}</span>
        </nav>
    );
};

export default GameTopBar;