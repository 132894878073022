import React, { useEffect, useState } from "react";
import { Link,useNavigate } from 'react-router-dom';
import ToastDialog from '../dialogs/ToastDialog';
import BottomNavbar from '../other-components/BottomNavbar';
import { openNewPage,copyText,AVATARS_ARR } from '../modals/Constants';
import { getStorage, deleteStorage } from '../modals/Storage';

function Activity() {
  const [pageConst, setGameData] = useState({
    pageTitle: "Activity",
    toastDialogShow: false,
    toastMessage: "",
});

  const navigate = useNavigate();
  const signOutAccount = () =>{
    if(deleteStorage()){
      navigate('/login', { replace: true });
    }
  }

  const updateToastDialogState = (data,msg) => {
    setGameData(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setGameData(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const copyMyId = () =>{
    copyText(getStorage("uid"));
    updateToastDialogState(true, "ID Copied!");
  }

  useEffect(() => {
    if(!getStorage("uid")){
      signOutAccount();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth ovf-scrl-y hide-sb app-bg">

        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

        <div className='zoomAnimView'>

          <div className="col-view v-center pd-10-15 bg-red-grad-3">
            <div className="col-view">
              <p className="ft-sz-25 cl-white">Activity</p>
              <p className="ft-sz-14 cl-white">Please remember to follow the event page.</p>
              <p className="ft-sz-14 cl-white">We will launch user feedback activities from time to time.</p>
            </div>
          </div>

          <div className="col-view pd-20 mg-t-10">

            <div className="g-v-2">

              <Link className="txt-deco-n col-view br-10 bg-white" to={"/redeemgiftcard"}>
                <img src={require("../icons/othericons/1.png")} className="w-100" alt="icon"/>
                <div className="col-view pd-15-10 mg-t-10">
                  <span className="cl-black ft-sz-18">Gifts</span>
                  <span className="cl-grey ft-sz-12">Enter the redemption code to receive gift rewards</span>
                </div>
              </Link>

              <Link className="txt-deco-n col-view br-10 bg-white" to={"/dailycheckin"}>
                <img src={require("../icons/othericons/2.png")} className="w-100" alt="icon"/>
                <div className="col-view pd-15-10 mg-t-10">
                  <span className="cl-black ft-sz-18">Attendance bonus</span>
                  <span className="cl-grey ft-sz-12">The more consecutive days you sign in, the higher the reward will be.</span>
                </div>
              </Link>

            </div>

          </div>

        </div>

        <BottomNavbar activeBar="activity"/>

      </div>
    </div>
  );
}

export default Activity;