import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import '../css/navbar.css';

const BottomNavbar = ({activeBar}) => {

    return (
      <div className="row-view">

        <nav className='btm-navbar bg-white ps-fx ps-btm z-i-100 res-wth'>
            
          <Link className={`nav-col trn-05 ${activeBar=="home" ? 'navBtnAnimate' : ''}`} to={"/home"}>
            <img src={require("../icons/home_icon_active.png")} className={`h-w-26 ${activeBar!="home" ? 'hide-v' : ''}`} alt="icon"/>
            <img src={require("../icons/home_icon.png")} className={`h-w-26 ${activeBar=="home" ? 'hide-v' : ''}`} alt="icon"/>
            <p>Home</p>
          </Link>

          <Link className={`nav-col ${activeBar=="activity" ? 'navBtnAnimate' : ''}`} to={"/activity"}>
            <img src={require("../icons/activity_icon_active.png")} className={`h-w-26 ${activeBar!="activity" ? 'hide-v' : ''}`} alt="icon"/>
            <img src={require("../icons/activity_icon.png")} className={`h-w-26 ${activeBar=="activity" ? 'hide-v' : ''}`} alt="icon"/>
            <p>Activity</p>
          </Link>

          <Link className={`nav-col`}>
          </Link>

          <Link className={`nav-col h-73-p ps-ab ps-lf-50 t-form-x ps-tp--27px ${activeBar=="promotion" ? 'navBtnAnimate' : ''}`} to={"/invite"}>
            <img src={require("../icons/promotion_icon.png")} className={`h-w-70`} alt="icon"/>
            <p>Promotion</p>
          </Link>

          <Link className={`nav-col ${activeBar=="recharge" ? 'navBtnAnimate' : ''}`} to={"/recharge"}>
            <img src={require("../icons/wallet_icon_active.png")} className={`h-w-26 ${activeBar!="recharge" ? 'hide-v' : ''}`} alt="icon"/>
            <img src={require("../icons/wallet_icon.png")} className={`h-w-26 ${activeBar=="recharge" ? 'hide-v' : ''}`} alt="icon"/>
            <p>Wallet</p>
          </Link>

          <Link className={`nav-col ${activeBar=="account" ? 'navBtnAnimate' : ''}`} to={"/account"}>
            <img src={require("../icons/account_icon_active.png")} className={`h-w-26 ${activeBar!="account" ? 'hide-v' : ''}`} alt="icon"/>
            <img src={require("../icons/account_icon.png")} className={`h-w-26 ${activeBar=="account" ? 'hide-v' : ''}`} alt="icon"/>
            <p>Account</p>
          </Link>

        </nav>

      </div>
    );
};

export default BottomNavbar;