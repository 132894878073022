import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import BottomNavbar from "../other-components/BottomNavbar";
import { openNewPage, copyText, AVATARS_ARR } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

function Account() {
  const [pageConst, setGameData] = useState({
    toastDialogShow: false,
    toastMessage: "",
  });

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    updateToastDialogState(true, "ID Copied!");
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh res-wth app-bg ovf-scrl-y hide-sb">
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />

        <div className="zoomAnimView">
          <div className="h-230-p row-view a-flex-start pd-20 pd-t-30 br-lr-b-2p5rem bg-grad-1">
            <div
              className="h-w-70 ovf-hidden br-50"
              onClick={() => navigate("/updateaccount", { replace: false })}
            >
              <img
                src={
                  AVATARS_ARR[Number(getStorage("avatarId")) - 1]["avatarURL"]
                }
                className="h-w-70"
                alt="icon"
              />
            </div>

            <div className="col-view mg-l-15">
              <Link className="row-view txt-deco-n" to={"/changename"}>
                <div className="cl-white ft-wgt-500 ft-sz-1p25rem">
                  {getStorage("uname")}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 200 200"
                  className="w-1p2-rem h-1p2-rem mg-l-10"
                  fill="#FFFFFF"
                >
                  <path d="M135.554 41.667v12.5h-89.72v91.666h99.999V100h12.5v45.833a12.5 12.5 0 0 1-12.5 12.5h-100a12.5 12.5 0 0 1-12.5-12.5V54.167a12.5 12.5 0 0 1 12.5-12.5h89.721Zm26.709 4.733 8.808 8.867-67.133 66.658.025.025-9.1.308.258-9.233.017.021L162.262 46.4Z"></path>
                </svg>
              </Link>

              <div
                className="cl-white ft-sz-14 mg-t-5"
                onClick={() => copyMyId()}
              >
                UID &nbsp; | &nbsp; {getStorage("uid")} 
                <img
                  src={require("../icons/copy_icon.png")}
                  className="h-10-p mg-l-15"
                  alt="icon"
                />
              </div>
              <div className="cl-white ft-sz-14 mg-t-5">
                Last Active: {getStorage("accountLastActive")}
              </div>
            </div>
          </div>

          <div className="col-view pd-20 mg-t--120">
            <div className="w-100 z-i-1">
              <div className="w-100 col-view pd-10-15 br-10 mg-t-20 bg-white bx-shadow">
                <span>Account Balance:</span>
                <span className="row-view ft-sz-23">
                  <img className="h-w-25 mg-r-10" src={require("../media/icons/wallet_fill_icon.png")} alt="icon" />
                  ₹{Number(getStorage("balance")).toLocaleString()}
                </span>

                <div className="line-hv-grey mg-t-10"></div>

                <div className="row-view sb-view pd-0-15 mg-t-10">
                  <Link
                    className="txt-deco-n col-view v-center br-10 bg-white"
                    to={"/recharge"}
                  >
                    <img
                      src={require("../media/icons/deposit_option_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-black ft-sz-15">Deposit</span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view v-center br-10 bg-white"
                    to={"/withdraw"}
                  >
                    <img
                      src={require("../media/icons/withdrawl_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-black ft-sz-15">Withdraw</span>
                  </Link>

                  <div
                    className="col-view v-center br-10 bg-white"
                    onClick={() => openNewPage(getStorage("appDownloadURL"))}
                  >
                    <img
                      src={require("../icons/download_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-black ft-sz-15">Download</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="g-v-2 mg-t-15">
              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/rechargerecords"}
              >
                <img
                  src={require("../media/icons/deposit_history_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className="cl-black ft-sz-17">Deposit</span>
                  <span className="cl-black ft-sz-12">My Deposit History</span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/withdrawrecords"}
              >
                <img
                  src={require("../media/icons/withdrawl_history_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className="cl-black ft-sz-17">Withdraw</span>
                  <span className="cl-black ft-sz-12">My Withdraw History</span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/myorders"}
              >
                <img
                  src={require("../media/icons/bet_history_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className="cl-black ft-sz-17">Bet</span>
                  <span className="cl-black ft-sz-12">My Bet History</span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/transactions"}
              >
                <img
                  src={require("../media/icons/transactions_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className="cl-black ft-sz-17">Transactions</span>
                  <span className="cl-black ft-sz-12">
                    My Transactions History
                  </span>
                </div>
              </Link>
            </div>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n mg-t-20 br-10 bg-white"
              onClick={() => openNewPage(getStorage("liveChatURL"))}
            >
              <div className="v-center">
                <img
                  src={require("../icons/live_support_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-drk-black mg-l-10">Live Support</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#000000"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>

            <Link
              className={`row-view cl-black pd-15 sb-view txt-deco-n mg-t-5 br-10 bg-grey-black ${
                Number(getStorage("accountLevel")) <= 2 ? "hide-v" : ""
              }`}
              to={"/agentreports"}
            >
              <div className="v-center">
                <img
                  src={require("../icons/transaction_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="mg-l-10">Agent Report</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#FFFFFF"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>

            <div
              className="row-view pd-15 sb-view mg-t-5 br-10 bg-white"
              onClick={() => openNewPage(getStorage("appDownloadURL"))}
            >
              <div className="v-center">
                <img
                  src={require("../icons/download_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-drk-black mg-l-10">Download</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#000000"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </div>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n mg-t-5 br-10 bg-white"
              to={"/redeemgiftcard"}
            >
              <div className="v-center">
                <img
                  src={require("../icons/gifts_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-drk-black mg-l-10">Redeem Gift</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#000000"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>

            <Link
              className="row-view cl-black pd-15 sb-view txt-deco-n mg-t-5 br-10 bg-white"
              to={"/devicemanager"}
            >
              <div className="v-center">
                <img
                  src={require("../icons/device_manager_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-drk-black mg-l-10">Device Manager</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#000000"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </Link>

            <div
              className="row-view pd-15 sb-view mg-t-5 br-10 bg-white"
              onClick={() => openNewPage(getStorage("telegramURL"))}
            >
              <div className="v-center">
                <img
                  src={require("../icons/notification_icon.png")}
                  className="h-w-28"
                  alt="icon"
                />
                <span className="cl-drk-black mg-l-10">Telegram</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#000000"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </div>

            <div
              className="row-view pd-15 sb-view mg-t-30 mg-b-100 br-10 bg-red-grad-2"
              onClick={() => signOutAccount()}
            >
              <div className="v-center">
                <span className="cl-white mg-l-10">Sign Out</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="tranf-rotate-180"
                fill="#FFFFFF"
              >
                <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
              </svg>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="account" />
      </div>
    </div>
  );
}

export default Account;
