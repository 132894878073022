import React from "react";
import { Link } from "react-router-dom";
import { getStorage } from "../modals/Storage";

const ImportantMessage = () => {
  return (
    <div className="w-100 h-52-p max-h-85-p row-view a-flex-start ovf-hidden pd-5-10 br-10 bg-l-blue">
      <div className="pd-10-0">
        <svg
          width="20"
          height="auto"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_589_37747)">
            <path
              d="M15.9993 4V28C11.3327 28 7.86502 21.8927 7.86502 21.8927H3.99935C3.26297 21.8927 2.66602 21.2958 2.66602 20.5594V11.3405C2.66602 10.6041 3.26297 10.0072 3.99935 10.0072H7.86502C7.86502 10.0072 11.3327 4 15.9993 4Z"
              fill="url(#paint0_linear_589_37747)"
            ></path>
            <path
              d="M21.334 10C21.7495 10.371 22.1261 10.7865 22.4567 11.2392C23.4265 12.5669 24.0007 14.2149 24.0007 16C24.0007 17.7697 23.4363 19.4045 22.4819 20.7262C22.1452 21.1923 21.7601 21.6195 21.334 22"
              stroke="url(#paint1_linear_589_37747)"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M22.8242 27.4571C26.7227 25.1302 29.3336 20.87 29.3336 16C29.3336 11.2057 26.8031 7.00234 23.005 4.65271"
              stroke="url(#paint2_linear_589_37747)"
              strokeWidth="1.8"
              strokeLinecap="round"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_589_37747"
              x1="9.33268"
              y1="4"
              x2="9.33268"
              y2="28"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF7C7C"></stop>
              <stop offset="0.74876" stopColor="#F54545"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_589_37747"
              x1="22.6673"
              y1="10"
              x2="22.6673"
              y2="22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF7C7C"></stop>
              <stop offset="0.74876" stopColor="#F54545"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_589_37747"
              x1="26.0789"
              y1="4.65271"
              x2="26.0789"
              y2="27.4571"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF7C7C"></stop>
              <stop offset="0.74876" stopColor="#F54545"></stop>
            </linearGradient>
            <clipPath id="clip0_589_37747">
              <rect width="32" height="32" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="ft-sz-14 mg-l-15 move-up-anim">
        {getStorage("impMessage")}
      </div>
    </div>
  );
};

export default ImportantMessage;
