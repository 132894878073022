import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BettingDialog from "../../dialogs/BettingDialog";
import LoadingDialog from "../../dialogs/LoadingDialog";
import MaintenanceDialog from "../../dialogs/MaintenanceDialog";
import ToastDialog from "../../dialogs/ToastDialog";
import WinDialog from "../../dialogs/WinLoseDialog/dialog";
import RuleDialog from "../../dialogs/WingoRuleDialog";
import { setStorage, getStorage } from "../../modals/Storage";
import { API_ACCESS_URL, openNewPage } from "../../modals/Constants";
import "../../../MainStyle.css";
import "../../css/WingoGame.css";
import styles from "../Wingo/Game.module.css";
import ImportantMessage from "../../other-components/ImportantMessage";

import audio1 from "../../media/audio/1.mp3";
import audio2 from "../../media/audio/2.mp3";

function Wingo1Min() {
  const navigate = useNavigate();
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisable] = useState(30);
  const [randomNumAnimationTime, setRandomNumAnimationTime] = useState(0);
  const [randomNumAnimation, setRandomNumAnimation] = useState(-1);

  const [isMusicOn, setIsMusicOn] = useState(getStorage("isMusicOn"));
  const [playComponent, setPlayComponent] = useState(0);

  const [recordsPageNum, setRecordsPageNum] = useState(1);
  const [recordsTotalPageNum, setRecordsTotalPageNum] = useState(0);

  const [pageConst, setGameData] = useState({
    pageTitle: "Wingo 1Min",
    gamePeriodId: "",
    gameCode: "WINGO1MIN",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    isControlEnable: true,
    requestAccountData: true,
    isLoadingShow: false,
    maintenanceDialogShow: false,
    ruleDialogShow: false,
    winDialogShow: false,
    toastDialogShow: false,
    toastMessage: "",
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    gameShortRecordList: [],
    mygameRecordsList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
    gameSelectedMultiply: "1",
  });

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "",
    constWinAmount: 0,
    constWinDialogState: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === "visible";
    if (isVisible) {
      getGameData(recordsPageNum);
    }
  }, []);

  const updateMusicStatus = (data) => {
    setIsMusicOn(data);
    setStorage("isMusicOn", data);
  };

  const openNewGame = (data) => {
    if (data == "1") {
      navigate("/wingo1min", { replace: true });
    } else if (data == "2") {
      navigate("/wingo3min", { replace: true });
    } else if (data == "3") {
      navigate("/wingo5min", { replace: true });
    } else if (data == "4") {
      navigate("/wingo10min", { replace: true });
    }
  };

  const updatePageNum = (data) =>{
    let updatedRecordsNum = recordsPageNum + Number(data);
    if(updatedRecordsNum >= 1 && updatedRecordsNum < 500 && recordsPageNum != recordsTotalPageNum){
      setRecordsPageNum(updatedRecordsNum);
      getGameData(updatedRecordsNum);
    }else if(Number(data) < 0 && updatedRecordsNum >= 1){
      setRecordsPageNum(updatedRecordsNum);
      getGameData(updatedRecordsNum);
    }
  }

  const updateBetMultiply = (data) => {
    setBettingDialogConst((previousState) => {
      return { ...previousState, gameSelectedMultiply: data };
    });
  };

  const updatePeriod = (data) => {
    setGameData((previousState) => {
      return { ...previousState, gamePeriodId: data[0].game_period_id };
    });
  };

  const updateWinDialogState = (data) => {
    setWinDialogConst((previousState) => {
      return { ...previousState, winDialogShow: data };
    });
  };

  const updateGameControl = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isControlEnable: data };
    });
  };

  const updateReqAcntData = (data) => {
    if (data == "false") {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: false };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: true };
      });
    }
  };

  const updateRemainSec = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingSec1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingSec2: val };
      });
    }
  };

  const updateRemainMin = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingMin1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingMin2: val };
      });
    }
  };

  const updateActiveTab = (data) => {
    setGameData((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-green";

    if (data == "r" || data == "rv") {
      returnVal = "dlg-thm-red";
    } else if (data == "v") {
      returnVal = "dlg-thm-violet";
    } else if (data == "b") {
      returnVal = "dlg-thm-orange";
    } else if (data == "s") {
      returnVal = "dlg-thm-blue";
    }

    return returnVal;
  };

  const updateBettingDialogState = (e, data, selected, title) => {
    if (selected != "") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, gameSelectedBet: selected };
      });
    }

    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"
    ) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });

      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogTitle: title };
      });

      setBettingDialogConst((previousState) => {
        return {
          ...previousState,
          bettingDialogTheme: getDialogThemeClassName(
            getColourCodeVal(selected)
          ),
        };
      });
    } else if (data == "dismiss") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: false };
      });
    }
  };

  const showGameRules = () => {
    setGameData((previousState) => {
      return { ...previousState, ruleDialogShow: true };
    });
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
    } else {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateMaintenanceDialog = (data) => {
    if (data == "dismiss") {
      navigate(-1);
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, maintenanceDialogShow: true };
      });
    }
  };

  const updateGameRuleDialog = (e, data) => {
    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"
    ) {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: data };
      });
    } else if (data == "dismiss") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: false };
      });
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else if (data == "b") {
      returnVal = "B";
    } else if (data == "s") {
      returnVal = "S";
    } else {
      returnVal = data;
    }

    return returnVal;
  };

  const isBigSmall = (data) => {
    let returnVal = "false";

    if (data == "b" || data == "s") {
      returnVal = "true";
    }

    return returnVal;
  };

  const getBigSmall = (data) => {
    let returnVal = "";

    if (data >= 5) {
      returnVal = "B";
    } else {
      returnVal = "S";
    }

    return returnVal;
  };

  const getWinCode = (data, isWin) => {
    let returnVal = "";

    if (isWin == "loss" && data == "s") {
      returnVal = "B";
    } else if (isWin == "loss" && data == "b") {
      returnVal = "S";
    } else if (isWin == "profit") {
      returnVal = getCapitalLetter(data);
    } else {
      returnVal = "";
    }

    return returnVal;
  };

  const getColourCodeVal = (data) => {
    let returnVal = "";

    if (data == "1" || data == "3" || data == "7" || data == "9") {
      returnVal = "g";
    } else if (data == "2" || data == "4" || data == "6" || data == "8") {
      returnVal = "r";
    } else if (data == "0") {
      returnVal = "rv";
    } else if (data == "5") {
      returnVal = "gv";
    } else if (data == "red") {
      returnVal = "r";
    } else if (data == "green") {
      returnVal = "g";
    } else if (data == "violet") {
      returnVal = "v";
    } else if (data == "b" || data == "B") {
      returnVal = "b";
    } else if (data == "s" || data == "S") {
      returnVal = "s";
    }

    return returnVal;
  };

  const updateGameRecords = (data) => {
    let tempData = [];
    let shortCutData = [];

    for (let i = 0; i < data.length; i++) {
      let matchResult = data[i]["match_result"];

      if (i < 5) {
        shortCutData.push(
          <div key={data[i]["period_id"] * 2} className="row-view sb-view">
            {matchResult == "0" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n0_icon.png")}
                alt="icon"
              />
            ) : matchResult == "1" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n1_icon.png")}
                alt="icon"
              />
            ) : matchResult == "2" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n2_icon.png")}
                alt="icon"
              />
            ) : matchResult == "3" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n3_icon.png")}
                alt="icon"
              />
            ) : matchResult == "4" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n4_icon.png")}
                alt="icon"
              />
            ) : matchResult == "5" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n5_icon.png")}
                alt="icon"
              />
            ) : matchResult == "6" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n6_icon.png")}
                alt="icon"
              />
            ) : matchResult == "7" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n7_icon.png")}
                alt="icon"
              />
            ) : matchResult == "8" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n8_icon.png")}
                alt="icon"
              />
            ) : matchResult == "9" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n9_icon.png")}
                alt="icon"
              />
            ) : (
              ""
            )}
          </div>
        );
      }

      tempData.push(
        <div key={data[i]["period_id"]} className="row-view mg-t-15 sb-view">
          <span className="w-50-p cl-drk-black ft-sz-15 ft-wgt-200">
            {data[i]["period_id"]}
          </span>
          <span
            className={`v-center w-30-p ft-sz-28 ft-wgt-400 ${
              getColourCodeVal(matchResult) == "r"
                ? "cl-red"
                : getColourCodeVal(matchResult) == "g"
                ? "cl-green"
                : getColourCodeVal(matchResult) == "rv"
                ? "cl-rv"
                : "cl-gv"
            }`}
          >
            {matchResult}
          </span>
          <span
            className={`v-center w-30-p br-50 cl-drk-black ft-sz-15 ft-wgt-200`}
          >
            {getBigSmall(matchResult) == "B" ? "Big" : "Small"}
          </span>
          <span className="v-center h-w-25">
            {getColourCodeVal(matchResult) == "rv" ? (
              <div className="row-view">
                <div className="h-w-12 br-50 bg-red"></div>
                <div className="h-w-12 br-50 bg-violet-2 mg-l-3"></div>
              </div>
            ) : getColourCodeVal(matchResult) == "gv" ? (
              <div className="row-view">
                <div className="h-w-12 br-50 bg-green"></div>
                <div className="h-w-12 br-50 bg-violet-2 mg-l-3"></div>
              </div>
            ) : getColourCodeVal(matchResult) == "r" ? (
              <div className="h-w-12 br-50 bg-red"></div>
            ) : (
              <div className="h-w-12 br-50 bg-green"></div>
            )}
          </span>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, gameRecordsList: tempData };
    });

    setGameData((previousState) => {
      return { ...previousState, gameShortRecordList: shortCutData };
    });
  };

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  };

  const recentBetDetails = (invested_amount) => {
    setStorage("recentwingo1minbet", pageConst.gamePeriodId);

    let tempData = [];

    tempData.push(
      <div key={60} className="row-view mg-t-15 sb-view">
        <span className="w-50-p cl-drk-black ft-sz-14">
          {pageConst.gamePeriodId}
        </span>
        <span
          className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
            getColourCodeVal(bettingDialogConst.gameSelectedBet) == "r"
              ? "bg-red"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "g"
              ? "bg-green"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "rv"
              ? "bg-rv"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "gv"
              ? "bg-gv"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
              ? "bg-drk-orange"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "s"
              ? "bg-blue"
              : "bg-violet"
          }`}
        >
          {getCapitalLetter(bettingDialogConst.gameSelectedBet)}
        </span>
        <span className={`cl-drk-black ft-sz-17 h-w-28 br-50 v-center`}></span>
        <span className={`cl-drk-black w-65-p ft-sz-15 txt-a-end`}>
          {invested_amount}.00
        </span>
      </div>
    );

    let finalArr = [tempData].concat(pageConst.mygameRecordsList);

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: finalArr };
    });
  };

  const updateMyGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      if (
        i == 0 &&
        getStorage("recentwingo1minbet") == data[i]["m_period_id"]
      ) {
        if (data[0]["m_status"] == "profit") {
          setWinDialogConst((previousState) => {
            return { ...previousState, constPeriodId: 'win 1min '+data[0]["m_period_id"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinAmount: data[0]["m_profit"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinDialogState: "win" };
          });

          setStorage("recentwingo1minbet", "");
          updateWinDialogState(true);
        } else if (data[0]["m_status"] == "loss") {
          setWinDialogConst((previousState) => {
            return { ...previousState, constPeriodId: 'win 1min '+data[0]["m_period_id"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinAmount: "0" };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinDialogState: "loss" };
          });

          setStorage("recentwingo1minbet", "");
          updateWinDialogState(true);
        }
      }

      let tempMatchReslt = "";
      if (data[i]["m_status"] == "profit") {
        if (Number(data[i]["m_profit"]) >= 1000000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else if (Number(data[i]["m_profit"]) >= 100000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else if (Number(data[i]["m_profit"]) >= 10000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else {
          tempMatchReslt = "+₹" + Number(data[i]["m_profit"]);
        }
      } else if (data[i]["m_status"] == "loss") {
        if (Number(data[i]["m_cost"]) >= 1000000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else if (Number(data[i]["m_cost"]) >= 100000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else if (Number(data[i]["m_cost"]) >= 10000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else {
          tempMatchReslt = "-₹" + Number(data[i]["m_cost"]);
        }
      } else {
        tempMatchReslt = "₹" + Number(data[i]["m_cost"]);
      }

      tempData.push(
        <div key={i} className="row-view mg-t-15 sb-view">
          <span className="w-50-p cl-drk-black ft-sz-14">
            {data[i].m_period_id}
          </span>
          <span
            className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
              getColourCodeVal(data[i]["m_color"]) == "r"
                ? "bg-red"
                : getColourCodeVal(data[i]["m_color"]) == "g"
                ? "bg-green"
                : getColourCodeVal(data[i]["m_color"]) == "rv"
                ? "bg-rv"
                : getColourCodeVal(data[i]["m_color"]) == "gv"
                ? "bg-gv"
                : getColourCodeVal(data[i]["m_color"]) == "b"
                ? "bg-drk-orange"
                : getColourCodeVal(data[i]["m_color"]) == "s"
                ? "bg-blue"
                : "bg-violet"
            }`}
          >
            {getCapitalLetter(data[i]["m_color"])}
          </span>
          <span
            className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
              isBigSmall(data[i]["m_color"]) == "true"
                ? getColourCodeVal(getBigSmall(data[i]["m_result"])) == "b"
                  ? data[i]["m_status"] != "wait"
                    ? "bg-drk-orange"
                    : data[i]["m_status"] != "wait"
                    ? "bg-drk-orange"
                    : ""
                  : getColourCodeVal(getBigSmall(data[i]["m_result"])) == "s"
                  ? data[i]["m_status"] != "wait"
                    ? "bg-blue"
                    : data[i]["m_status"] != "wait"
                    ? "bg-blue"
                    : ""
                  : ""
                : getColourCodeVal(data[i]["m_result"]) == "r"
                ? "bg-red"
                : getColourCodeVal(data[i]["m_result"]) == "g"
                ? "bg-green"
                : getColourCodeVal(data[i]["m_result"]) == "rv"
                ? "bg-rv"
                : getColourCodeVal(data[i]["m_result"]) == "gv"
                ? "bg-gv"
                : getColourCodeVal(data[i]["m_result"]) == "b"
                ? "bg-drk-orange"
                : getColourCodeVal(data[i]["m_result"]) == "s"
                ? "bg-blue"
                : getColourCodeVal(data[i]["m_color"]) == "v"
                ? data[i]["m_status"] != "wait"
                  ? "bg-violet"
                  : ""
                : ""
            } `}
          >
            {isBigSmall(data[i]["m_color"]) == "true"
              ? getWinCode(data[i]["m_color"], data[i]["m_status"])
              : getCapitalLetter(data[i]["m_result"])}
          </span>
          <span
            className={`w-65-p ft-sz-15 txt-a-end ${
              data[i]["m_status"] == "loss"
                ? "cl-red"
                : data[i]["m_status"] == "profit"
                ? "cl-green"
                : "cl-white"
            }`}
          >
            {tempMatchReslt}
          </span>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: tempData };
    });
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const randomNumberSelect = () => {
    setRandomNumAnimationTime(30);
  };

  function getGameData(pageNum) {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-setup-common",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisable(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);
          setRecordsTotalPageNum(data.total_match_pages);
          setStorage("balance", data.account_balance);
        } else if (data.status_code == "under_maintenance") {
          updateMaintenanceDialog("true");
        } else {
          updateToastDialogState(
            true,
            "Something went wrong! Please try again or login!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        console.log(error);
      }
    };

    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PROJECT_NAME=" +
        pageConst.gameCode +
        "&PAGE=" + pageNum
    );
  }

  function setUpTimer(data) {
    if (data == "true") {
      updateReqAcntData("true");
    }

    if (pageConst.requestAccountData == true) {
      updateReqAcntData("false");
      getGameData(recordsPageNum);
    }
  }

  useEffect(() => {
    if (isMusicOn == "true" && playComponent > 1 && playComponent <= 4) {
      let audio = new Audio(audio1);
      audio.play();
    } else if (isMusicOn == "true" && playComponent == 1) {
      let audio = new Audio(audio2);
      audio.play();
    }
  }, [playComponent, setIsMusicOn]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer("false");

    const milliSecInterval = setInterval(() => {
      if (randomNumAnimationTime > 0) {
        let randBetNum = getRandomNum(0, 10, "int");

        setRandomNumAnimation(randBetNum);
        setRandomNumAnimationTime(randomNumAnimationTime - 1);
        if (randomNumAnimationTime == 1) {
          setRandomNumAnimation(-1);
          updateBettingDialogState(
            null,
            true,
            randBetNum,
            "Select " + randBetNum
          );
        }
      }
    }, 100);

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          setPlayComponent(0);
          setUpTimer("true");
          setCount(60);
        } else {
          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            if (seconds <= 4) {
              setPlayComponent(seconds);
            }

            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (
            remainingSec > disableTime &&
            pageConst.isControlEnable == false
          ) {
            updateGameControl(true);
          }
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(milliSecInterval);
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [remainingSec, randomNumAnimationTime]);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <LoadingDialog intentData={pageConst} />
        <WinDialog
          intentData={winDialogConst}
          updateState={updateWinDialogState}
        />
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          toastUpdate={updateToastDialogState}
          recentBetDetails={recentBetDetails}
        />
        <MaintenanceDialog
          intentData={pageConst}
          updateState={updateMaintenanceDialog}
        />

        <div className="col-view app-bg">
          <div className="col-view">
            <div className="h-300-p w-100 br-lr-b-1p5rem bg-red-grad-3">
              <div className="row-view sb-view pd-10-15">
                <Link onClick={() => navigate(-1)}>
                  <img
                    className="h-25-p"
                    src={require("../../icons/arrow_left_icon_white.png")}
                  />
                </Link>

                <img
                  className="res-app-logo"
                  src={require("../../icons/logo.png")}
                />

                <div className="row-view v-center">
                  <img
                    className="h-27-p"
                    src={require("../../icons/live_chat_icon.png")}
                    onClick={() => openNewPage(getStorage("liveChatURL"))}
                  />

                  <img
                    className={`h-27-p mg-l-10 ${
                      isMusicOn == "false" ? "hide-v" : ""
                    }`}
                    src={require("../../icons/music_on_icon.png")}
                    onClick={() => updateMusicStatus("false")}
                  />
                  <img
                    className={`h-27-p mg-l-10 ${
                      isMusicOn == "true" ? "hide-v" : ""
                    }`}
                    src={require("../../icons/music_off_icon.png")}
                    onClick={() => updateMusicStatus("true")}
                  />
                </div>
              </div>

              <div className="pd-15">
                <div className="col-view v-center pd-10 br-15 bg-white">
                  <div className="row-view">
                    <p className="cl-black ft-sz-23 ft-wgt-500">
                      ₹{Number(getStorage("balance")).toLocaleString()}
                    </p>
                    <img
                      className="h-25-p mg-l-10"
                      src={require("../../media/icons/refresh_icon.png")}
                      alt="icon"
                      onClick={() => setUpTimer("true")}
                    />
                  </div>
                  <p className="row-view ft-sz-14 cl-drk-black">
                    <img
                      className="h-w-20 mg-r-10"
                      src={require("../../media/icons/wallet_fill_icon.png")}
                      alt="icon"
                    />
                    Wallet Balance
                  </p>

                  <div className="res-wallet-btns v-center w-100 mg-t-15">
                    <Link
                      className="v-center cl-white ft-wgt-500 br-20 bg-red-grad-2"
                      to={"/recharge"}
                    >
                      Deposit
                    </Link>
                    <Link
                      className="v-center cl-grey ft-wgt-500 br-20 br-a-grey bg-transparent"
                      to={"/withdraw"}
                    >
                      Withdraw
                    </Link>
                  </div>
                </div>
              </div>

              <div className="pd-0-15">
                <div className="g-v-4 w-100 br-10 bg-white">
                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-drk-red bg-red-grad-active`}
                    onClick={() => openNewGame("1")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/1.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>1Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("2")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>3Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("3")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>5Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("4")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>10Min</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pd-15 mg-t-30">
              <ImportantMessage />

              <div
                className={[styles.GameBettingView, styles.mgT25px].join(" ")}
              >
                <div className="col-view pd-10">
                  <div
                    className="w-fit-content txt-deco-n pd-5-10 ft-sz-13 cl-white ft-wgt-500 br-5 br-a-l-white bg-transparent"
                    onClick={() => showGameRules()}
                  >
                    How to play
                  </div>
                  <p className="cl-white ft-sz-13 mg-t-10">
                    {pageConst.pageTitle}
                  </p>
                  <div className="g-v-5 mg-t-15">
                    {pageConst.gameShortRecordList}
                  </div>
                </div>

                <div className="col-view pd-10">
                  <div className="col-view a-right">
                    <span className="cl-white ft-sz-14">Time Remaining</span>
                    <div
                      className={[
                        styles.countDownTimerView,
                        styles.mgT10px,
                      ].join(" ")}
                    >
                      <span className="bg-white">
                        {pageConst.remainingMin1}
                      </span>
                      <span className="bg-white">
                        {pageConst.remainingMin2}
                      </span>
                      :
                      <span className="bg-white">
                        {pageConst.remainingSec1}
                      </span>
                      <span className="bg-white">
                        {pageConst.remainingSec2}
                      </span>
                    </div>

                    <span className="res-ft-sz-18 cl-white ltr-s-1p8 ft-wgt-500 mg-t-10">
                      {pageConst.gamePeriodId}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={[styles.pd10px15px].join(" ")}>
              <div
                className={[
                  styles.positionRel,
                  styles.bgWhite,
                  styles.borderR10px,
                  !pageConst.isControlEnable
                    ? playComponent <= 0
                      ? "disable-view"
                      : ""
                    : "",
                ].join(" ")}
              >
                <div
                  className={`res-timeout-view h-100 ps-ab ps-tp-50 ps-lf-50 t-form-xy v-center z-i-100 br-10 bg-l-black ${
                    !pageConst.isControlEnable
                      ? playComponent > 0
                        ? ""
                        : "hide-v"
                      : "hide-v"
                  }`}
                >
                  <h1 className="v-center cl-white br-10 bg-red-2">0</h1>
                  <h1 className="v-center cl-white br-10 bg-red-2 mg-l-15">
                    {playComponent}
                  </h1>
                </div>

                <div
                  className={[
                    styles.positionRel,
                    styles.joinColorView,
                    !pageConst.isControlEnable
                      ? playComponent <= 0
                        ? "disable-view"
                        : ""
                      : "",
                  ].join(" ")}
                >
                  <div
                    className="v-center w-100 cl-white bg-green"
                    onClick={() =>
                      updateBettingDialogState(
                        null,
                        true,
                        "green",
                        "Join Green"
                      )
                    }
                  >
                    Green
                  </div>

                  <div
                    className="v-center w-100 cl-white bg-violet"
                    onClick={() =>
                      updateBettingDialogState(
                        null,
                        true,
                        "violet",
                        "Join Violet"
                      )
                    }
                  >
                    Violet
                  </div>

                  <div
                    className="v-center w-100 cl-white bg-red"
                    onClick={() =>
                      updateBettingDialogState(null, true, "red", "Join Red")
                    }
                  >
                    Red
                  </div>
                </div>

                <div className={[styles.pd0px15px].join(" ")}>
                  <div
                    className={[
                      styles.positionRel,
                      styles.joinNumView,
                      !pageConst.isControlEnable
                        ? playComponent <= 0
                          ? "disable-view"
                          : ""
                        : "",
                    ].join(" ")}
                  >
                    <img
                      className={[
                        randomNumAnimation == 0
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n0_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "0", "Select 0")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 1
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n1_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "1", "Select 1")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 2
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n2_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "2", "Select 2")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 3
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n3_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "3", "Select 3")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 4
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n4_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "4", "Select 4")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 5
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n5_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "5", "Select 5")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 6
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n6_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "6", "Select 6")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 7
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n7_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "7", "Select 7")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 8
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n8_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "8", "Select 8")
                      }
                    />
                    <img
                      className={[
                        randomNumAnimation == 9
                          ? styles.randomNumSelectedAnim
                          : "",
                      ]}
                      src={require("../../icons/wingo/n9_icon.png")}
                      alt="icon"
                      onClick={() =>
                        updateBettingDialogState(null, true, "9", "Select 9")
                      }
                    />
                  </div>
                </div>

                <div className={[styles.joinRandomView]}>
                  <div onClick={() => randomNumberSelect()}>Random</div>
                  <div
                    className={[
                      bettingDialogConst.gameSelectedMultiply == "1"
                        ? styles.bgActiveGreen
                        : "",
                    ]}
                    onClick={() => updateBetMultiply(1)}
                  >
                    X1
                  </div>
                  <div
                    className={
                      bettingDialogConst.gameSelectedMultiply == "5"
                        ? styles.bgActiveGreen
                        : ""
                    }
                    onClick={() => updateBetMultiply(5)}
                  >
                    X5
                  </div>
                  <div
                    className={[
                      bettingDialogConst.gameSelectedMultiply == "10"
                        ? styles.bgActiveGreen
                        : "",
                    ]}
                    onClick={() => updateBetMultiply(10)}
                  >
                    X10
                  </div>
                  <div
                    className={[
                      bettingDialogConst.gameSelectedMultiply == "30"
                        ? styles.bgActiveGreen
                        : "",
                    ]}
                    onClick={() => updateBetMultiply(30)}
                  >
                    X30
                  </div>
                </div>

                <div className={[styles.joinBigSmallView]}>
                  <div
                    className={[styles.bgOrange]}
                    onClick={() =>
                      updateBettingDialogState(null, true, "b", "Join Big")
                    }
                  >
                    Big
                  </div>

                  <div
                    className={[styles.bgBlue]}
                    onClick={() =>
                      updateBettingDialogState(null, true, "s", "Join Small")
                    }
                  >
                    Small
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-0-15 mg-t-20">
            <div className="tab-slct-v w-100">
              <div
                className={`v-center pd-10 br-10 ft-sz-18 w-100 cl-white ${
                  pageConst.tabActiveReord == "everyoneorder"
                    ? "bg-red-grad-2"
                    : "bg-grey-2"
                }`}
                onClick={() => updateActiveTab("everyoneorder")}
              >
                Game History
              </div>
              <div
                className={`v-center pd-10 br-10 ft-sz-18 w-100 cl-white mg-l-10 ${
                  pageConst.tabActiveReord == "myorder"
                    ? "bg-red-grad-2"
                    : "bg-grey-2"
                }`}
                onClick={() => updateActiveTab("myorder")}
              >
                My History
              </div>
            </div>

            <div className="col-view br-10 mg-t-25 mg-b-15 bg-white">
              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "everyoneorder" ? "hide-v" : ""
                }`}
              >
                <div className="row-view sb-view ft-sz-16 pd-10-15 cl-white br-right-t br-left-t bg-red-grad-2">
                  <span>Period</span>
                  <span className="mg-l-15">Number</span>
                  <span>Big Small</span>
                  <span>Color</span>
                </div>

                <div className="col-view pd-10-15">
                  {pageConst.gameRecordsList}
                </div>

                <div className={[styles.loadMoreBtnsView, styles.viewCenter, styles.mgT10px].join(" ")}>

                  <div className={[recordsPageNum > 1 ? styles.selectedView : styles.notSelectedView]} onClick={() => updatePageNum("-1")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#FFFFFF"
                    >
                      <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                    </svg>
                  </div>

                  <div className={[styles.pageNumShowView, styles.fontS14Px].join(" ")}>
                    {recordsPageNum}/{recordsTotalPageNum}
                  </div>

                  <div className={[recordsPageNum == recordsTotalPageNum ? styles.notSelectedView : styles.selectedView]} onClick={() => updatePageNum("+1")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="tranf-rotate-180"
                      fill="#FFFFFF"
                    >
                      <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "myorder" ? "hide-v" : ""
                }`}
              >
                <div className="row-view sb-view br-right-t br-left-t pd-10-15 cl-white bg-red-grad-2">
                  <span>Period</span>
                  <span>Select</span>
                  <span>Result</span>
                  <span>Amount</span>
                </div>

                <div className="col-view pd-10-15">
                  {pageConst.mygameRecordsList}
                </div>

                <Link
                  className="w-100 v-center pd-10 mg-t-15 br-15 cl-golden txt-deco-n"
                  to={"/myorders"}
                >
                  View All Records
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wingo1Min;
