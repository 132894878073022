import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BettingDialog from "../../dialogs/BettingDialog";
import LoadingDialog from "../../dialogs/LoadingDialog";
import MaintenanceDialog from "../../dialogs/MaintenanceDialog";
import ToastDialog from "../../dialogs/ToastDialog";
import WinDialog from "../../dialogs/WinDialog";
import RuleDialog from "../../dialogs/K3LotteryDialog";
import { setStorage, getStorage } from "../../modals/Storage";
import { API_ACCESS_URL, openNewPage } from "../../modals/Constants";
import "../../../MainStyle.css";
import styles from "../k3Lottery/Game.module.css";
import ImportantMessage from "../../other-components/ImportantMessage";

import audio1 from "../../media/audio/1.mp3";
import audio2 from "../../media/audio/2.mp3";

function K3Lottery5Min() {
  const navigate = useNavigate();
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisable] = useState(30);

  const [isMusicOn, setIsMusicOn] = useState(getStorage("isMusicOn"));
  const [playComponent, setPlayComponent] = useState(0);

  const [diceAnimTime, setDiceAnimTime] = useState(-1);
  const [dice1Num, setDice1Num] = useState(1);
  const [dice2Num, setDice2Num] = useState(1);
  const [dice3Num, setDice3Num] = useState(1);

  const [dice1RollNum, setDice1RollNum] = useState(1);
  const [dice2RollNum, setDice2RollNum] = useState(1);
  const [dice3RollNum, setDice3RollNum] = useState(1);

  const [shouldWinLossDialogShow, setShouldWinLossDialogShow] = useState(false);

  const [pageConst, setGameData] = useState({
    pageTitle: "K3 Lottery",
    gamePeriodId: "",
    gameCode: "K3LOTTERY5MIN",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    isControlEnable: true,
    requestAccountData: true,
    isLoadingShow: false,
    maintenanceDialogShow: false,
    ruleDialogShow: false,
    winDialogShow: false,
    toastDialogShow: false,
    toastMessage: "",
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    gameShortRecordList: [],
    mygameRecordsList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
  });

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "",
    constWinAmount: 0,
    constWinDialogState: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === "visible";
    if (isVisible) {
      getGameData();
    }
  }, []);

  const updateMusicStatus = (data) => {
    setIsMusicOn(data);
    setStorage("isMusicOn", data);
  };

  const openNewGame = (data) => {
    if (data == "1") {
      navigate("/k3lottery1min", { replace: true });
    } else if (data == "2") {
      navigate("/k3lottery3min", { replace: true });
    } else if (data == "3") {
      navigate("/k3lottery5min", { replace: true });
    } else if (data == "4") {
      navigate("/k3lottery10min", { replace: true });
    }
  };

  const updatePeriod = (data) => {
    setGameData((previousState) => {
      return { ...previousState, gamePeriodId: data[0].game_period_id };
    });
  };

  const updateWinDialogState = (data) => {
    setWinDialogConst((previousState) => {
      return { ...previousState, winDialogShow: data };
    });
  };

  const updateGameControl = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isControlEnable: data };
    });
  };

  const updateReqAcntData = (data) => {
    if (data == "false") {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: false };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: true };
      });
    }
  };

  const updateRemainSec = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingSec1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingSec2: val };
      });
    }
  };

  const updateRemainMin = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingMin1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingMin2: val };
      });
    }
  };

  const updateActiveTab = (data) => {
    setGameData((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-green";

    if (data == "r" || data == "rv" || data == "o") {
      returnVal = "dlg-thm-red";
    } else if (data == "v") {
      returnVal = "dlg-thm-violet";
    } else if (data == "b") {
      returnVal = "dlg-thm-orange";
    } else if (data == "s") {
      returnVal = "dlg-thm-blue";
    }

    return returnVal;
  };

  const updateBettingDialogState = (e, data, selected, title) => {
    if (selected != "") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, gameSelectedBet: selected };
      });
    }

    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"
    ) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });

      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogTitle: title };
      });

      setBettingDialogConst((previousState) => {
        return {
          ...previousState,
          bettingDialogTheme: getDialogThemeClassName(
            getColourCodeVal(selected)
          ),
        };
      });
    } else if (data == "dismiss") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: false };
      });
    }
  };

  const showGameRules = () => {
    setGameData((previousState) => {
      return { ...previousState, ruleDialogShow: true };
    });
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
    } else {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateMaintenanceDialog = (data) => {
    if (data == "dismiss") {
      navigate(-1);
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, maintenanceDialogShow: true };
      });
    }
  };

  const updateGameRuleDialog = (e, data) => {
    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"
    ) {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: data };
      });
    } else if (data == "dismiss") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: false };
      });
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else if (data == "b") {
      returnVal = "B";
    } else if (data == "s") {
      returnVal = "S";
    } else if (data == "o") {
      returnVal = "O";
    } else if (data == "e") {
      returnVal = "E";
    } else {
      returnVal = data;
    }

    return returnVal;
  };

  const isBigSmall = (data) => {
    let returnVal = "false";

    if (data == "b" || data == "s") {
      returnVal = "true";
    }

    return returnVal;
  };

  const getBigSmall = (data) => {
    let returnVal = "";

    if (data >= 5) {
      returnVal = "B";
    } else {
      returnVal = "S";
    }

    return returnVal;
  };

  const getWinCode = (data, isWin) => {
    let returnVal = "";

    if (isWin == "loss" && data == "s") {
      returnVal = "B";
    } else if (isWin == "loss" && data == "b") {
      returnVal = "S";
    } else if (isWin == "profit") {
      returnVal = getCapitalLetter(data);
    } else {
      returnVal = "";
    }

    return returnVal;
  };

  const getColourCodeVal = (data) => {
    let returnVal = "";

    if (
      data == "3" ||
      data == "5" ||
      data == "7" ||
      data == "9" ||
      data == "11" ||
      data == "13" ||
      data == "15" ||
      data == "17"
    ) {
      returnVal = "r";
    } else if (
      data == "4" ||
      data == "6" ||
      data == "8" ||
      data == "10" ||
      data == "12" ||
      data == "14" ||
      data == "16" ||
      data == "18"
    ) {
      returnVal = "g";
    } else if (data == "b" || data == "B") {
      returnVal = "b";
    } else if (data == "s" || data == "S") {
      returnVal = "s";
    } else if (data == "o" || data == "O") {
      returnVal = "o";
    } else if (data == "e" || data == "E") {
      returnVal = "e";
    }

    return returnVal;
  };

  const updateGameRecords = (data) => {
    let tempData = [];
    let shortCutData = [];

    for (let i = 0; i < data.length; i++) {
      let tempMatchResult = data[i]["match_result"];
      let matchResult = 0,
        dice1Num = 0,
        dice2Num = 0,
        dice3Num = 0;

      for (let i = 0; i < tempMatchResult.length; i++) {
        matchResult += Number(tempMatchResult[i]);
        if (i == 0) {
          dice1Num = Number(tempMatchResult[i]);
        } else if (i == 1) {
          dice2Num = Number(tempMatchResult[i]);
        } else if (i == 2) {
          dice3Num = Number(tempMatchResult[i]);
        }
      }

      if (i == 0) {
        let dice1RollNum = 1,
          dice2RollNum = 1,
          dice3RollNum = 1;
        for (let i = 0; i < tempMatchResult.length; i++) {
          if (i == 0) {
            dice1RollNum = Number(tempMatchResult[i]);
            setDice1RollNum(Number(tempMatchResult[i]));
          } else if (i == 1) {
            dice2RollNum = Number(tempMatchResult[i]);
            setDice2RollNum(Number(tempMatchResult[i]));
          } else if (i == 2) {
            dice3RollNum = Number(tempMatchResult[i]);
            setDice3RollNum(Number(tempMatchResult[i]));
          }
        }

        if (diceAnimTime == 0) {
          setDiceAnimTime(3);
        } else {
          showDiceAnimation(dice1RollNum, dice2RollNum, dice3RollNum);
        }
      }

      if (i < 5) {
        shortCutData.push(
          <div key={data[i]["period_id"] * 2} className="row-view sb-view">
            {matchResult == "0" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n0_icon.png")}
                alt="icon"
              />
            ) : matchResult == "1" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n1_icon.png")}
                alt="icon"
              />
            ) : matchResult == "2" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n2_icon.png")}
                alt="icon"
              />
            ) : matchResult == "3" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n3_icon.png")}
                alt="icon"
              />
            ) : matchResult == "4" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n4_icon.png")}
                alt="icon"
              />
            ) : matchResult == "5" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n5_icon.png")}
                alt="icon"
              />
            ) : matchResult == "6" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n6_icon.png")}
                alt="icon"
              />
            ) : matchResult == "7" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n7_icon.png")}
                alt="icon"
              />
            ) : matchResult == "8" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n8_icon.png")}
                alt="icon"
              />
            ) : matchResult == "9" ? (
              <img
                className="result-shortcut-icon"
                src={require("../../icons/wingo/n9_icon.png")}
                alt="icon"
              />
            ) : (
              ""
            )}
          </div>
        );
      }

      tempData.push(
        <div key={data[i]["period_id"]} className="row-view mg-t-15 sb-view">
          <span className={[styles.w50px, styles.fontS14Px].join(" ")}>
            {data[i]["period_id"]}
          </span>
          <span
            className={[
              styles.fontS14Px,
              styles.w40px,
              styles.fontW400,
              styles.mgL5px,
              `${
                getColourCodeVal(matchResult) == "r"
                  ? "cl-red"
                  : getColourCodeVal(matchResult) == "g"
                  ? "cl-green"
                  : getColourCodeVal(matchResult) == "rv"
                  ? "cl-rv"
                  : "cl-gv"
              }`,
            ].join(" ")}
          >
            {matchResult}
            <span className={[styles.fontS14Px, styles.mgL10px].join(" ")}>
              {Number(matchResult) > 10 ? "Big" : "Small"}
            </span>
          </span>
          <span
            className={[
              styles.viewCenter,
              styles.fontS14Px,
              styles.w30px,
              styles.mgL5px,
            ].join(" ")}
          >
            {Number(matchResult) % 2 == 0 ? "Even" : "Odd"}
          </span>

          <div className={[styles.viewCenter]}>
            <div
              className={[
                styles.smallDiceView,
                `${
                  dice1Num == 1
                    ? styles.smallDiceView1
                    : dice1Num == 2
                    ? styles.smallDiceView2
                    : dice1Num == 3
                    ? styles.smallDiceView3
                    : dice1Num == 4
                    ? styles.smallDiceView4
                    : dice1Num == 5
                    ? styles.smallDiceView5
                    : dice1Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>

            <div
              className={[
                styles.smallDiceView,
                `${
                  dice2Num == 1
                    ? styles.smallDiceView1
                    : dice2Num == 2
                    ? styles.smallDiceView2
                    : dice2Num == 3
                    ? styles.smallDiceView3
                    : dice2Num == 4
                    ? styles.smallDiceView4
                    : dice2Num == 5
                    ? styles.smallDiceView5
                    : dice2Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>

            <div
              className={[
                styles.smallDiceView,
                `${
                  dice3Num == 1
                    ? styles.smallDiceView1
                    : dice3Num == 2
                    ? styles.smallDiceView2
                    : dice3Num == 3
                    ? styles.smallDiceView3
                    : dice3Num == 4
                    ? styles.smallDiceView4
                    : dice3Num == 5
                    ? styles.smallDiceView5
                    : dice3Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>
          </div>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, gameRecordsList: tempData };
    });

    setGameData((previousState) => {
      return { ...previousState, gameShortRecordList: shortCutData };
    });
  };

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  };

  const recentBetDetails = (invested_amount) => {
    setStorage("recentk3lottery3minbet", pageConst.gamePeriodId);

    let tempData = [];

    tempData.push(
      <div key={60} className="row-view mg-t-15 sb-view">
        <span className="w-50-p cl-drk-black ft-sz-14">
          {pageConst.gamePeriodId}
        </span>
        <span
          className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
            getColourCodeVal(bettingDialogConst.gameSelectedBet) == "r"
              ? "bg-red"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "g"
              ? "bg-green"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "rv"
              ? "bg-rv"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "gv"
              ? "bg-gv"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
              ? "bg-drk-orange"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "s"
              ? "bg-blue"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "o"
              ? "bg-red"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "e"
              ? "bg-green"
              : "bg-violet"
          }`}
        >
          {getCapitalLetter(bettingDialogConst.gameSelectedBet)}
        </span>
        <span className={`cl-drk-black ft-sz-17 h-w-28 br-50 v-center`}></span>
        <span className={`cl-drk-black w-65-p ft-sz-15 txt-a-end`}>
          {invested_amount}.00
        </span>
      </div>
    );

    let finalArr = [tempData].concat(pageConst.mygameRecordsList);

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: finalArr };
    });
  };

  const updateMyGameRecords = (data) => {
    let tempData = [];

    for (let i = 0; i < data.length; i++) {
      if (
        i == 0 &&
        getStorage("recentk3lottery3minbet") == data[i]["m_period_id"]
      ) {
        if (data[0]["m_status"] == "profit") {
          setWinDialogConst((previousState) => {
            return { ...previousState, constPeriodId: data[0]["m_period_id"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinAmount: data[0]["m_profit"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinDialogState: "win" };
          });

          setStorage("recentk3lottery3minbet", "");
          setShouldWinLossDialogShow(true);
        } else if (data[0]["m_status"] == "loss") {
          setWinDialogConst((previousState) => {
            return { ...previousState, constPeriodId: data[0]["m_period_id"] };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinAmount: "0" };
          });

          setWinDialogConst((previousState) => {
            return { ...previousState, constWinDialogState: "loss" };
          });

          setStorage("recentk3lottery3minbet", "");
          setShouldWinLossDialogShow(true);
        }
      }

      let tempMatchReslt = "";
      if (data[i]["m_status"] == "profit") {
        if (Number(data[i]["m_profit"]) >= 1000000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else if (Number(data[i]["m_profit"]) >= 100000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else if (Number(data[i]["m_profit"]) >= 10000) {
          tempMatchReslt =
            "+₹" + getFixedDecimalVal(Number(data[i]["m_profit"]) / 1000) + "K";
        } else {
          tempMatchReslt = "+₹" + Number(data[i]["m_profit"]);
        }
      } else if (data[i]["m_status"] == "loss") {
        if (Number(data[i]["m_cost"]) >= 1000000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else if (Number(data[i]["m_cost"]) >= 100000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else if (Number(data[i]["m_cost"]) >= 10000) {
          tempMatchReslt =
            "-₹" + getFixedDecimalVal(Number(data[i]["m_cost"]) / 1000) + "K";
        } else {
          tempMatchReslt = "-₹" + Number(data[i]["m_cost"]);
        }
      } else {
        tempMatchReslt = "₹" + Number(data[i]["m_cost"]);
      }

      let tempMatchResult = data[i]["m_result"];
      let matchResult = 0;

      for (let i = 0; i < tempMatchResult.length; i++) {
        matchResult += Number(tempMatchResult[i]);
      }

      tempData.push(
        <div key={i} className="row-view mg-t-15 sb-view">
          <span className="w-50-p cl-drk-black ft-sz-14">
            {data[i].m_period_id}
          </span>
          <span
            className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
              getColourCodeVal(data[i]["m_color"]) == "r"
                ? "bg-red"
                : getColourCodeVal(data[i]["m_color"]) == "g"
                ? "bg-green"
                : getColourCodeVal(data[i]["m_color"]) == "rv"
                ? "bg-rv"
                : getColourCodeVal(data[i]["m_color"]) == "gv"
                ? "bg-gv"
                : getColourCodeVal(data[i]["m_color"]) == "b"
                ? "bg-drk-orange"
                : getColourCodeVal(data[i]["m_color"]) == "s"
                ? "bg-blue"
                : getColourCodeVal(data[i]["m_color"]) == "o"
                ? "bg-red"
                : getColourCodeVal(data[i]["m_color"]) == "e"
                ? "bg-green"
                : "bg-violet"
            }`}
          >
            {getCapitalLetter(data[i]["m_color"])}
          </span>
          <span
            className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${
              isBigSmall(data[i]["m_color"]) == "true"
                ? getColourCodeVal(getBigSmall(matchResult)) == "b"
                  ? data[i]["m_status"] != "wait"
                    ? "bg-drk-orange"
                    : data[i]["m_status"] != "wait"
                    ? "bg-drk-orange"
                    : ""
                  : getColourCodeVal(getBigSmall(matchResult)) == "s"
                  ? data[i]["m_status"] != "wait"
                    ? "bg-blue"
                    : data[i]["m_status"] != "wait"
                    ? "bg-blue"
                    : ""
                  : ""
                : getColourCodeVal(matchResult) == "r"
                ? "bg-red"
                : getColourCodeVal(matchResult) == "g"
                ? "bg-green"
                : getColourCodeVal(matchResult) == "b"
                ? "bg-drk-orange"
                : getColourCodeVal(matchResult) == "s"
                ? "bg-blue"
                : getColourCodeVal(matchResult) == "o"
                ? "bg-red"
                : getColourCodeVal(matchResult) == "e"
                ? "bg-green"
                : ""
            } `}
          >
            {isBigSmall(data[i]["m_color"]) == "true"
              ? getWinCode(data[i]["m_color"], data[i]["m_status"])
              : getCapitalLetter(matchResult)}
          </span>
          <span
            className={`w-65-p ft-sz-15 txt-a-end ${
              data[i]["m_status"] == "loss"
                ? "cl-red"
                : data[i]["m_status"] == "profit"
                ? "cl-green"
                : "cl-white"
            }`}
          >
            {tempMatchReslt}
          </span>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: tempData };
    });
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  function getGameData() {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-setup-k3lottery",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisable(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);
          setStorage("balance", data.account_balance);
        } else if (data.status_code == "under_maintenance") {
          updateMaintenanceDialog("true");
        } else {
          updateToastDialogState(
            true,
            "Something went wrong! Please try again or login!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        console.log(error);
      }
    };

    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PROJECT_NAME=" +
        pageConst.gameCode
    );
  }

  function setUpTimer(data) {
    if (data == "true") {
      updateReqAcntData("true");
    }

    if (pageConst.requestAccountData == true) {
      updateReqAcntData("false");
      getGameData();
    }
  }

  const showDiceAnimation = (dice1, dice2, dice3) => {
    if (
      Number.isInteger(dice1) &&
      Number.isInteger(dice2) &&
      Number.isInteger(dice3)
    ) {
      setDice1Num(dice1);
      setDice2Num(dice2);
      setDice3Num(dice3);
    } else {
      setDice1Num(getRandomNum(1, 6, "interger"));
      setDice2Num(getRandomNum(1, 6, "interger"));
      setDice3Num(getRandomNum(1, 6, "interger"));
    }
  };

  useEffect(() => {
    if (isMusicOn == "true" && playComponent > 1 && playComponent <= 4) {
      let audio = new Audio(audio1);
      audio.play();
    } else if (isMusicOn == "true" && playComponent == 1) {
      let audio = new Audio(audio2);
      audio.play();
    }
  }, [playComponent, setIsMusicOn]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer("false");

    const milliSecInterval = setInterval(() => {
      if (diceAnimTime > 0) {
        setDiceAnimTime(diceAnimTime - 1);
        showDiceAnimation("rand", "rand", "rand");
      } else if (diceAnimTime == 0) {
        if (shouldWinLossDialogShow) {
          setShouldWinLossDialogShow(false);
          updateWinDialogState(true);
        }

        setDiceAnimTime(-1);
        showDiceAnimation(dice1RollNum, dice2RollNum, dice3RollNum);
      }
    }, 100);

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          setDiceAnimTime(0);
          setPlayComponent(0);
          setUpTimer("true");
          setCount(60);
        } else {
          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            if (seconds <= 4) {
              setPlayComponent(seconds);
            }

            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (
            remainingSec > disableTime &&
            pageConst.isControlEnable == false
          ) {
            updateGameControl(true);
          }
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
      clearInterval(milliSecInterval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [remainingSec]);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <LoadingDialog intentData={pageConst} />
        <WinDialog
          intentData={winDialogConst}
          updateState={updateWinDialogState}
        />
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          toastUpdate={updateToastDialogState}
          recentBetDetails={recentBetDetails}
        />
        <MaintenanceDialog
          intentData={pageConst}
          updateState={updateMaintenanceDialog}
        />

        <div className="col-view app-bg">
          <div className="col-view">
            <div className="h-300-p w-100 br-lr-b-1p5rem bg-red-grad-3">
              <div className="row-view sb-view pd-10-15">
                <Link onClick={() => navigate(-1)}>
                  <img
                    className="h-25-p"
                    src={require("../../icons/arrow_left_icon_white.png")}
                  />
                </Link>

                <img
                  className="res-app-logo"
                  src={require("../../icons/logo.png")}
                />

                <div className="row-view v-center">
                  <img
                    className="h-27-p"
                    src={require("../../icons/live_chat_icon.png")}
                    onClick={() => openNewPage(getStorage("liveChatURL"))}
                  />

                  <img
                    className={`h-27-p mg-l-10 ${
                      isMusicOn == "false" ? "hide-v" : ""
                    }`}
                    src={require("../../icons/music_on_icon.png")}
                    onClick={() => updateMusicStatus("false")}
                  />
                  <img
                    className={`h-27-p mg-l-10 ${
                      isMusicOn == "true" ? "hide-v" : ""
                    }`}
                    src={require("../../icons/music_off_icon.png")}
                    onClick={() => updateMusicStatus("true")}
                  />
                </div>
              </div>

              <div className="pd-15">
                <div className="col-view v-center pd-10 br-15 bg-white">
                  <div className="row-view">
                    <p className="cl-black ft-sz-23 ft-wgt-500">
                      ₹{Number(getStorage("balance")).toLocaleString()}
                    </p>
                    <img
                      className="h-25-p mg-l-10"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL2SURBVHgB7VdNTttAFH5vxrDOEdKgSt013IAo0DWcoHACyrIqUEOouoSeAG5AWCG1RE5PAMtKLUl6g6xR7Nf3xo7j0CQeExM2/qQoY2fezDfv55sXgAIFChQoMA/QZpLnlksD7VRhDpDC/rtPv+/gibAienNSuQWCuYhGm/UBsan00lHt469eFltlNYvgJ+QAAigR0bY/eOi2GpXPWWytPJoHJH0CpTdJ0e4wOoh4Ud+/37GxXxjRJH40Ki5vbDyKBGf1w85ems2LEBUkybKnV9MKbWaOSrhaxyvbN42VTcgZGwcdl/3UNiQCfztt/lSiTLIcOOqWkM65DC5bJ5Wu9/VNGXIEr30VDd+nzVVTSJaYpEcE5XhRHgf+g5cnWWcQNKNh6Tpl3YlEA63PhZjRvQiI0MubbM3t9Wzn/kdU9I2AwpwkjKvRR73FX30hyzp4KV6HBWKM6E2jssui7MqYv484ydvD36QquTprEHq56mvl2ZLlYvR47dsskWgdV06NTbRHTFSKByOSjG9hVY5DyBKoregxA1mqmvmWkTBRRfggNsMeQw1JmuLhpJZc1H7gTltk4+BPGwl3RmT1ecq+kCUSIoXJqMp+MVFYWiqZ4mGSSi/XOMn7szauH95fjMhSqsaGYp48nPImzTNRRTqVMQI2k1E1RGthSGtqEKzadjVCVmzkYzN//eC+mYxE62RlLBI68GNJNA7z/bEeYOYVKifk03fDjTq5XLdy04WXSNiUSDcVUWlzdNbiqD5ymF2bx7jOSTtN2iAY2RuRNE9r4TvcmxTVNKJxrjqDQRlyQn2/cyaF8vi9vJMUmWQzk2hYVGHjgBDk2phIoZicRV4f4Y7laG+SJA6RmneJnOprZ3k161+IvGBVINI5jcnXC5C1KqbkPS9NiXgZFgxryfn+5XVVk385bP3Eu5K/AdFfmAOOs3xhE6FM2hjqKrpsltroWoMPvL7feZU+7QmIegO+k/EtP5ZhDnBLebXBcgUFChQoUOBZ8Q833WX9Sjr96wAAAABJRU5ErkJggg=="
                      alt="icon"
                      onClick={() => setUpTimer("true")}
                    />
                  </div>
                  <p className="ft-sz-14 cl-drk-black">Wallet Balance</p>

                  <div className="res-wallet-btns v-center w-100 mg-t-15">
                    <Link
                      className="v-center cl-white ft-wgt-500 br-20 bg-red-grad-2"
                      to={"/recharge"}
                    >
                      Deposit
                    </Link>
                    <Link
                      className="v-center cl-grey ft-wgt-500 br-20 br-a-grey bg-transparent"
                      to={"/withdraw"}
                    >
                      Withdraw
                    </Link>
                  </div>
                </div>
              </div>

              <div className="pd-0-15">
                <div className="g-v-4 w-100 br-10 bg-white">
                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("1")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/1.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>1Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("2")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>3Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-drk-red bg-red-grad-active`}
                    onClick={() => openNewGame("3")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>5Min</span>
                  </div>

                  <div
                    className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-black`}
                    onClick={() => openNewGame("4")}
                  >
                    <img
                      className="h-50-p"
                      src={require("../../icons/wingo/2.png")}
                      alt="icon"
                    />
                    <span>Win Go</span>
                    <span>10Min</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="pd-15 mg-t-30">
              <ImportantMessage />
            </div>

            <div className={`ps-rl col-view pd-10 mg-10-15 br-10 bg-white`}>
              <div className="row-view sb-view">
                <div className="col-view pd-10">
                  <div
                    className="w-fit-content txt-deco-n pd-5-10 ft-sz-13 cl-red ft-wgt-500 br-5 br-a-primary bg-transparent"
                    onClick={() => showGameRules()}
                  >
                    How to play
                  </div>

                  <span className="res-ft-sz-18 ltr-s-2p2 ft-wgt-600 mg-t-10">
                    {pageConst.gamePeriodId}
                  </span>
                </div>

                <div className="col-view pd-10">
                  <div className="col-view a-right">
                    <span className="ft-sz-14">Time Remaining</span>
                    <div
                      className={[
                        styles.countDownTimerView,
                        styles.mgT10px,
                      ].join(" ")}
                    >
                      <span>{pageConst.remainingMin1}</span>
                      <span>{pageConst.remainingMin2}</span>:
                      <span>{pageConst.remainingSec1}</span>
                      <span>{pageConst.remainingSec2}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={[styles.lotteryResultView]}>
                <div className={[styles.lotteryCubesHolder]}>
                  <div
                    className={[
                      styles.diceView,
                      `${
                        dice1Num == 1
                          ? styles.diceView1
                          : dice1Num == 2
                          ? styles.diceView2
                          : dice1Num == 3
                          ? styles.diceView3
                          : dice1Num == 4
                          ? styles.diceView4
                          : dice1Num == 5
                          ? styles.diceView5
                          : dice1Num == 6
                          ? styles.diceView6
                          : ""
                      }`,
                    ].join(" ")}
                  ></div>
                  <div
                    className={[
                      styles.diceView,
                      `${
                        dice2Num == 1
                          ? styles.diceView1
                          : dice2Num == 2
                          ? styles.diceView2
                          : dice2Num == 3
                          ? styles.diceView3
                          : dice2Num == 4
                          ? styles.diceView4
                          : dice2Num == 5
                          ? styles.diceView5
                          : dice2Num == 6
                          ? styles.diceView6
                          : ""
                      }`,
                    ].join(" ")}
                  ></div>
                  <div
                    className={[
                      styles.diceView,
                      `${
                        dice3Num == 1
                          ? styles.diceView1
                          : dice3Num == 2
                          ? styles.diceView2
                          : dice3Num == 3
                          ? styles.diceView3
                          : dice3Num == 4
                          ? styles.diceView4
                          : dice3Num == 5
                          ? styles.diceView5
                          : dice3Num == 6
                          ? styles.diceView6
                          : ""
                      }`,
                    ].join(" ")}
                  ></div>
                </div>
              </div>

              <div
                className={`ps-rl ${
                  !pageConst.isControlEnable
                    ? playComponent <= 0
                      ? "disable-view"
                      : ""
                    : ""
                }`}
              >
                <div
                  className={`res-timeout-view h-100 ps-ab ps-tp-50 ps-lf-50 t-form-xy v-center z-i-100 br-10 bg-l-black ${
                    !pageConst.isControlEnable
                      ? playComponent > 0
                        ? ""
                        : "hide-v"
                      : "hide-v"
                  }`}
                >
                  <h1 className="v-center cl-white br-10 bg-red-2">0</h1>
                  <h1 className="v-center cl-white br-10 bg-red-2 mg-l-15">
                    {playComponent}
                  </h1>
                </div>

                <div
                  className={[
                    styles.joinBallView,
                    "pd-10",
                    "br-10",
                    "bg-grey",
                  ].join(" ")}
                >
                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "3", "Join 3")
                        }
                      >
                        3
                      </div>
                    </div>

                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "4", "Join 4")
                        }
                      >
                        4
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "5", "Join 5")
                        }
                      >
                        5
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "6", "Join 6")
                        }
                      >
                        6
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "7", "Join 7")
                        }
                      >
                        7
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "8", "Join 8")
                        }
                      >
                        8
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "9", "Join 9")
                        }
                      >
                        9
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "10", "Join 10")
                        }
                      >
                        10
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "11", "Join 11")
                        }
                      >
                        11
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "12", "Join 12")
                        }
                      >
                        12
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "13", "Join 13")
                        }
                      >
                        13
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "14", "Join 14")
                        }
                      >
                        14
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "15", "Join 15")
                        }
                      >
                        15
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "16", "Join 16")
                        }
                      >
                        16
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.redBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.redBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "17", "Join 17")
                        }
                      >
                        17
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>

                  <div
                    className={[styles.viewCenter, styles.flexDCol].join(" ")}
                  >
                    <div
                      className={[
                        styles.ballView,
                        styles.greenBall,
                        styles.viewCenter,
                      ].join(" ")}
                    >
                      <div
                        className={[
                          styles.greenBallTxt,
                          styles.fontS25Px,
                          styles.fontW700,
                        ].join(" ")}
                        onClick={() =>
                          updateBettingDialogState(null, true, "18", "Join 18")
                        }
                      >
                        18
                      </div>
                    </div>
                    <span className={[styles.fontS13Px]}>7x</span>
                  </div>
                </div>

                <div
                  className={[styles.joinBigSmallOddEvenView, "row-view"].join(
                    " "
                  )}
                >
                  <div
                    className="col-view v-center"
                    onClick={() =>
                      updateBettingDialogState(null, true, "b", "Join Big")
                    }
                  >
                    Big
                    <span>(2x)</span>
                  </div>

                  <div
                    className="col-view v-center"
                    onClick={() =>
                      updateBettingDialogState(null, true, "s", "Join Small")
                    }
                  >
                    Small
                    <span>(2x)</span>
                  </div>

                  <div
                    className="col-view v-center"
                    onClick={() =>
                      updateBettingDialogState(null, true, "o", "Join Odd")
                    }
                  >
                    Odd
                    <span>(2x)</span>
                  </div>

                  <div
                    className="col-view v-center"
                    onClick={() =>
                      updateBettingDialogState(null, true, "e", "Join Even")
                    }
                  >
                    Even
                    <span>(2x)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-0-15 mg-t-20">
            <div className="tab-slct-v w-100">
              <div
                className={`v-center pd-10 br-10 ft-sz-18 w-100 cl-white ${
                  pageConst.tabActiveReord == "everyoneorder"
                    ? "bg-red-grad-2"
                    : "bg-grey-2"
                }`}
                onClick={() => updateActiveTab("everyoneorder")}
              >
                Game History
              </div>
              <div
                className={`v-center pd-10 br-10 ft-sz-18 w-100 cl-white mg-l-10 ${
                  pageConst.tabActiveReord == "myorder"
                    ? "bg-red-grad-2"
                    : "bg-grey-2"
                }`}
                onClick={() => updateActiveTab("myorder")}
              >
                My History
              </div>
            </div>

            <div className="col-view br-10 mg-t-25 mg-b-15 bg-white">
              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "everyoneorder" ? "hide-v" : ""
                }`}
              >
                <div className="row-view sb-view ft-sz-16 pd-10-15 cl-white br-right-t br-left-t bg-red-grad-2">
                  <span>Period</span>
                  <span>Sum</span>
                  <span>Results</span>
                </div>

                <div className="col-view pd-10-15">
                  {pageConst.gameRecordsList}
                </div>
              </div>

              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "myorder" ? "hide-v" : ""
                }`}
              >
                <div className="row-view sb-view br-right-t br-left-t pd-10-15 cl-white bg-red-grad-2">
                  <span>Period</span>
                  <span>Select</span>
                  <span>Result</span>
                  <span>Amount</span>
                </div>

                <div className="col-view pd-10-15">
                  {pageConst.mygameRecordsList}
                </div>

                <Link
                  className="w-100 v-center pd-10 mg-t-15 br-15 cl-golden txt-deco-n"
                  to={"/myorders"}
                >
                  View All Records
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default K3Lottery5Min;
