import React from 'react'
import { Link,useNavigate  } from 'react-router-dom';
import '../css/navbar.css';

const TopBar2 = ({intentData,multiBtn1,updateState}) => {
  const navigate = useNavigate();
  
    return (
        <nav className="ps-fx ps-tp res-wth pd-10 col-view z-i-120 bg-red-grad-5 topbar-shadow">
          
          <div className='row-view sb-view'>
           <Link className={`${multiBtn1 =='' ? '' : 'hide-v'}`} onClick={() => navigate(-1)}>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF"><path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path></svg>
           </Link>

           <img className="h-40-p br-5" src={require('../icons/logo.png')} /> 
          </div>

          <div className='col-view mg-l-15'>
             <span className={`cl-white ft-sz-18 ${intentData.pageTitle =='' ? 'hide-v' : ''}`}>{intentData.pageTitle}</span>
             <span className='cl-white ft-sz-14 mg-t-10'>Please enter your mobile number to continue.</span>
           </div>     

        </nav>
    );
};

export default TopBar2;